import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, DESKTOP_GRID_SIZE } from 'consts';
import { BackgroundImage } from 'components/ui';

export const Container = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
`;

export const Image = styled(BackgroundImage)`
    div {
        height: 100%;
    }
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 7}rem;
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 12rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 6rem;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE - DESKTOP_GRID_COLUMN * 2 - DESKTOP_GRID_SPACING * 3}rem;
        padding: 12rem 0;
    }

    @media ${MediaQueries.tablet} {
        flex-direction: column;
        padding: 12rem 7.4rem 0rem;
        align-items: center;
    }

    @media ${MediaQueries.phone} {
        flex-direction: column;
        padding: 6rem 2rem 0rem 2rem;
        align-items: center;
    }
`;

export const Title = styled.h2`
    color: ${Colors.white};
    text-align: center;
`;

export const Description = styled.p`
    color: ${Colors.white};
    margin-top: 4rem;
    text-align: center;
    white-space: pre-line;
`;

export const CirclesContainer = styled.div`
    position: absolute;
    z-index: 9;
    width: 100%;

    svg {
        width: 100%;
        transform: translate(53%, -15%);
    }

    @media ${MediaQueries.tablet} {
        svg {
            width: 100%;
            transform: translate(60%, -7%);
        }
    }
`;
