import styled, { css } from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, DESKTOP_GRID_SIZE } from 'consts';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${MediaQueries.tablet} {
        padding: 0 2rem;
    }
`;

export const Title = styled.h1`
    justify-content: center;
    color: ${Colors.violet};
    padding: 24rem 0rem 12rem;

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        padding: 7rem 0rem 7rem;
    }
`;

export const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: ${Colors.violet};
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
        padding-bottom: 8rem;
    }

    @media ${MediaQueries.tablet} {
        padding: 0 7.4rem;
        padding-bottom: 14rem;
    }

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
    }
`;

export const ProcessContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${MediaQueries.desktop} {
        justify-content: space-between;
        margin-bottom: 13rem;

        :nth-child(2n) {
            flex-direction: row-reverse;
        }
        :nth-child(2n + 1) {
            flex-direction: row;
        }
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 4rem;
    }
`;

export const TextContainer = styled.div<{ align: 'left' | 'right' }>`
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    align-items: center;
    justify-content: center;

    @media ${MediaQueries.phone} {
        padding-bottom: 4rem;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 6}rem;
        ${({ align }) => {
            if (align === 'left')
                return css`
                    align-items: flex-start;

                    p {
                        text-align: start;
                    }
                `;
            if (align === 'right')
                return css`
                    align-items: flex-end;

                    p {
                        text-align: end;
                    }
                `;
        }}
    }
`;

export const Picture = styled.img`
    @media ${MediaQueries.desktop} {
        width: 58rem;
        height: 43.5rem;
    }

    @media ${MediaQueries.tablet} {
        width: 100%;
    }

    @media ${MediaQueries.phone} {
        width: 100%;
    }
`;

export const ItemTitle = styled.h3<{ color: string }>`
    margin: 4rem 0 2rem 0;
    text-align: center;
    color: ${(props) => props.color};

    @media ${MediaQueries.tablet} {
        font-size: 3.7rem;
        font-weight: bold;
        margin: 0rem 0 2rem 0;
    }

    @media ${MediaQueries.phone} {
        font-size: 2.4rem;
        margin: 0rem 0 2rem 0;
        font-weight: 500;
    }
`;

export const Description = styled.p`
    line-height: 1.5;
    text-align: center;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 5 + DESKTOP_GRID_SPACING * 4}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: 48rem;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;

    @media ${MediaQueries.phone} {
        margin: 4rem 0rem 1rem;

        svg {
            width: 6rem;
            height: 6rem;
        }
    }

    @media ${MediaQueries.tablet} {
        margin: 4rem 0rem 4rem;
    }
`;

export const CirclesContainer = styled.div`
    position: absolute;
    z-index: -9;
    width: 100%;

    svg {
        width: 100%;
        transform: translate(-57%, 95%);
    }
`;
