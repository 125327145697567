import React from 'react';

import { ourProcess } from 'data/why-work-with-us.json';
import { MediaQueries, Icons, Colors } from 'environment';
import { useMediaQuery } from 'hooks';

import {
    Container,
    ProcessContainer,
    TextContainer,
    Title,
    ItemTitle,
    Description,
    Picture,
    IconContainer,
    ListContainer,
    CirclesContainer
} from './OurProcess.style';

export function OurProcess() {
    const isDesktop = useMediaQuery(MediaQueries.desktop);
    const colors = [Colors.green, Colors.magenta, Colors.orange, Colors.violet];

    return (
        <Container>
            <Title>{ourProcess.title}</Title>
            <ListContainer>
                {ourProcess.items.map((item, index) => {
                    const Icon = require(`../../../../static/images/${item.icon.src}`);
                    return (
                        <ProcessContainer key={`our-process-${item.title}-${index}`}>
                            <Picture src={`/images/${item.image.src}`} />
                            <TextContainer align={index % 2 === 0 ? 'left' : 'right'}>
                                <IconContainer>
                                    <Icon />
                                </IconContainer>
                                <ItemTitle color={colors[index]}>{item.title}</ItemTitle>
                                <Description>{item.description}</Description>
                            </TextContainer>
                        </ProcessContainer>
                    );
                })}
            </ListContainer>
            <CirclesContainer>{isDesktop && <Icons.CirclesLeft2 />}</CirclesContainer>
        </Container>
    );
}
