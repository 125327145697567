import React from 'react';

import { buildProject } from 'data/why-work-with-us.json';
import { whyWorkWithUsContactFormBottom } from 'data/analytics.json';

import { ButtonContainer, Container, Content, Title, Description } from './BuildYourProduct.style';
import { ContactFormModal } from '../ContactFormModal';

export function BuildYourProduct() {
    return (
        <Container>
            <Content>
                <Title>{buildProject.title}</Title>
                <Description>{buildProject.description}</Description>
                <ButtonContainer>
                    <ContactFormModal eventName={whyWorkWithUsContactFormBottom} />
                </ButtonContainer>
            </Content>
        </Container>
    );
}
