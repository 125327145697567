import React, { useEffect } from 'react';

import {
    WorkWithUsHero,
    DigitalTransformation,
    EndToEndServices,
    OurProcess,
    Award,
    BuildYourProduct
} from 'components/workWithUs';

import seo from 'data/seo.json';
import { SEO } from 'components/ui';
import { PAGE_LOADED_WHY_WORK_WITH_US, PAGE_UNLOADED_WHY_WORK_WITH_US } from 'consts';

export default function WorkWithUs() {
    useEffect(() => {
        document.dispatchEvent(new CustomEvent(PAGE_LOADED_WHY_WORK_WITH_US));
        return () => {
            document.dispatchEvent(new CustomEvent(PAGE_UNLOADED_WHY_WORK_WITH_US));
        };
    }, []);

    return (
        <>
            <SEO
                title={seo.whyWorkWithUsTitle}
                description={seo.whyWorkWithUsDescription}
                ogTitle={seo.whyWorkWithUsTitle}
                ogDescription={seo.whyWorkWithUsDescription}
                ogImage={seo.servicesOgImage}
            />
            <WorkWithUsHero />
            <DigitalTransformation />
            <EndToEndServices />
            <OurProcess />
            <Award />
            <BuildYourProduct />
        </>
    );
}
