import React from 'react';

import { digitalTransformation } from 'data/why-work-with-us.json';

import {
    Container,
    Description,
    Content,
    Title,
    FeaturesContainer,
    Feature,
    Label
} from './DigitalTransformation.style';

export function DigitalTransformation() {
    return (
        <Container>
            <Content>
                <Title>{digitalTransformation.title}</Title>
                <Description>{digitalTransformation.description}</Description>
                <FeaturesContainer>
                    {digitalTransformation.features.map((item, label) => {
                        const Icon = require(`../../../../static/images/${item.icon}`);
                        return (
                            <Feature key={`feature-container-${label}`}>
                                <Icon />
                                <Label>{item.label}</Label>
                            </Feature>
                        );
                    })}
                </FeaturesContainer>
            </Content>
        </Container>
    );
}
