import React from 'react';

import content from 'data/why-work-with-us.json';
import { logo } from 'data/landing.json';
import { whyWorkWithUsContactFormTop } from 'data/analytics.json';
import { useQueryContext } from 'hooks';
import { ContactFormModal } from '../ContactFormModal';

import {
    Container,
    Content,
    Title,
    Description,
    ButtonContainer,
    WorkBackgroundImage,
    ImageLink
} from './WorkWithUsHero.style';

export function WorkWithUsHero() {
    const {
        images: {
            whyWorkWithUs: { hero: image }
        }
    } = useQueryContext();

    return (
        <Container>
            <WorkBackgroundImage
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                alt={content.hero.alt}
            >
                <Content>
                    <ImageLink to="/">
                        <img src={`/images/${logo.src}`} alt={logo.alt} />
                    </ImageLink>
                    <Title>{content.title}</Title>
                    <Description>{content.description}</Description>
                    <ButtonContainer>
                        <ContactFormModal eventName={whyWorkWithUsContactFormTop} />
                    </ButtonContainer>
                </Content>
            </WorkBackgroundImage>
        </Container>
    );
}
